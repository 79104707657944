export const TRANSLATION_SOURCE_TYPE = {
    SELF : 0,
    OVERRIDEN : 1,
    SYSTEM_TRANSLATION : 2,
    COMPANY_DEFAULT : 3,
    SYSTEM_DEFAULT : 4
}

export const TRANSLATION_TYPE = {
    RETAIL: 1 << 0,
    BO: 1 << 1,
    WIDGET: 1 << 2,
    AGENT: 1 << 3
}

export const BO_GROUP_ID = "24cde1b8b5a44baab289267515ce3d51";
export const WIDGET_GROUP_ID = "7977eebb776c40e9983a062ee63c6829";
export const AGENT_GROUP_ID = "0d178ab2b00844d7a159272c87b6a314";

export const VALID_GROUP_IDS = [
    "24cde1b8b5a44baab289267515ce3d51", // BO
    "7977eebb776c40e9983a062ee63c6829", // Widget
    "0d178ab2b00844d7a159272c87b6a314", // Agent
    "0de4d91ed5784ee5926713706c6f1330", // Common
    "5fb115ee1be5479eb2e0bba3423229fe", // Authenticatoin
    "7abb236a9db04c3e9b6b41efb109e9f2", // Validation
    "910f66d11f5c411a9123a2cd428b281e" // Errors
]