//#region react
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
//#endregion

//#region libraries
import { Row, Col, Form } from 'antd';
//#endregion

//#region actions
import {
    setRealTimeSportBetsFilters
} from "store/actions/dashboard/betHistory/realTimeSportBets.action";
//#endregion

//#region components
import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import Select from "components/common/select";
import Input from "components/common/input";
import DatePicker from 'components/common/datePicker';
import TimePicker from "components/common/timepicker";
//#endregion

//#region hooks
import useUserCurrencies from 'hooks/useUserCurrencies';
import useAutosuggestion from 'hooks/useAutosuggestion';
import useDate from 'hooks/useDate';
import useProjectType from 'hooks/useProjectType';
//#endregion

//#region utils
import { getCurrencyFieldOptions } from 'utils/currency';
import { isMobile } from 'utils/common';
//#endregion

//#region constants
import { FILTER_REAL_TIME_SPORT_BET_DATA_TYPE } from "constants/filter.constants";
import { BET_TYPE, BETTING_TYPE, PLATFORM_BET_STATE } from "constants/bet.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
//#endregion

const FIELD_NAMES = {
    PLAYER_EXTERNAL_ID: "playerExternalId",
    DATE_TYPE: "filterType",
    DAY: "day",
    TIME: "time",
    CURRENCY_CODE: "currencyCode",
    PLAYER_USERNAME_OR_ID: "playerUserNameOrId",
    USERNAME_OR_ID: "userNameOrId",
    BET_STATE: "betState",
    BETSLIP_TYPE: "betSlipType",
    BETTING_TYPE: "bettingType",
}

/** Real Time Sport Bets Page Filters Component */
const Filters = ({
    setRealTimeSportBetsFilters,
    getRealTimeSportBets,
    filters,
    globalProjectId,
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const [ userCurrencies, getUserCurrencies ] = useUserCurrencies(false);

    const currencyFieldOptions = getCurrencyFieldOptions(userCurrencies);

    const { hasLiteMode } = useProjectType();

    const [ playerNames, getPlayerNames, resetPlayerNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.PLAYER,
        autoGet: false
    });

    const handlePlayersChange = v => {
        if(v?.length >= 3){
            getPlayerNames({"userNameOrId" : v})
        } else if(v?.length === 0){
            resetPlayerNames();
        }
    }

    return (
        <FiltersWrapper
            loadFn={getRealTimeSportBets}
            setFiltersFn={setRealTimeSportBetsFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            searchFieldName="playerUserNameOrId"
            onInit={() => {
                if(!hasLiteMode){
                    getUserCurrencies();
                }
            }}
        >
            <Row gutter={[16, 0]}>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.bets.dateType')}
                        name={FIELD_NAMES.DATE_TYPE}
                    >
                        <Select
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.dateType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            options={[
                                { value: FILTER_REAL_TIME_SPORT_BET_DATA_TYPE.BET_DATE_TIME, text: t("backoffice.bets.betDateTime") },
                                { value: FILTER_REAL_TIME_SPORT_BET_DATA_TYPE.CALCULATED_DATE_TIME, text: t("backoffice.bets.calculatedDateTime") },
                            ]}
                        />
                    </Form.Item>
                </Col>

                <Col span={12} >
                    <Form.Item
                        label={t('backoffice.common.dateAndTime')}
                        name={FIELD_NAMES.DAY}
                    >
                        <DatePicker
                            className="rt--datepicker"
                            popupClassName='rt--datepicker-popup'
                            format={`${dateService.getFormat(false)}`}
                            disabledDate={d => !dateService.isInRange(d, dateService.daysAgo(4), dateService.endOfDay() )}
                            showToday={false}
                            showNow={false}
                            allowClear={false}
                            placeholder={t('backoffice.common.selectDate')}
                            suffixIcon={<i className='icon-date rt--font-bigest' />}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            inputReadOnly={isMobile()}
                        />
                    </Form.Item>
                </Col>

                <Col span={12} >
                    <Form.Item
                        label=" "
                        name={FIELD_NAMES.TIME}
                        className='rt--form-item-without-label'
                    >
                        <TimePicker.RangePicker
                            format='HH:mm'
                            allowClear={false}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            className='rt--timepicker'
                            popupClassName='rt--timepicker-dropdown'
                            separator={<div className='rt--timepicker-separator'/>}
                            placement="bottomRight"
                            showNow={false}
                            inputReadOnly={true}
                        />
                    </Form.Item>
                </Col>

                {
                    !hasLiteMode && (
                        <Col span={24}>
                            <Form.Item
                                label={t('backoffice.bets.currency')}
                                name={FIELD_NAMES.CURRENCY_CODE}
                            >
                                <Select
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.currency')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    options={[
                                        { value: "", text: t("backoffice.common.all") },
                                        ...currencyFieldOptions
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    )
                }

                

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.bets.playerExternalId')}
                        name={FIELD_NAMES.PLAYER_EXTERNAL_ID}
                        className="rt--general-form-item"
                        data-placeholder={`${t("backoffice.common.enter")} ${t("backoffice.bets.playerExternalId")}`}
                    >
                        <Input
                            placeholder={`${t("backoffice.common.enter")} ${t("backoffice.bets.playerExternalId")}`}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t("backoffice.bets.status")}
                        name={FIELD_NAMES.BET_STATE}
                    >
                        <Select
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            options={[
                                { value: "", text: t("backoffice.common.all") },
                                { value: PLATFORM_BET_STATE.PENDING, text: t("backoffice.bets.pending") },
                                { value: PLATFORM_BET_STATE.WON, text: t("backoffice.bets.won") },
                                { value: PLATFORM_BET_STATE.LOST, text: t("backoffice.bets.lost") },
                                { value: PLATFORM_BET_STATE.CANCELLED, text: t("backoffice.bets.cancelled") }
                            ]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t("backoffice.bets.betType")}
                        name={FIELD_NAMES.BETSLIP_TYPE}
                    >
                        <Select
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.type')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            options={[
                                { value: "", text: t("backoffice.common.all") },
                                { value: BET_TYPE.SINGLE, text: t("backoffice.bets.single") },
                                { value: BET_TYPE.MULTI, text: t("backoffice.bets.multi") },
                                { value: BET_TYPE.SYSTEM, text: t("backoffice.bets.system") }
                            ]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t("backoffice.bets.bettingType")}
                        name={FIELD_NAMES.BETTING_TYPE}
                    >
                        <Select
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.bettingType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            options={[
                                { value: "", text: t("backoffice.common.all") },
                                { value: BETTING_TYPE.PREMATCH, text: t("backoffice.common.preMatch") },
                                { value: BETTING_TYPE.LIVE, text: t("backoffice.common.live") },
                                { value: BETTING_TYPE.KOMBI, text: t("backoffice.common.kombi") }
                            ]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Function get real time sport bets */
    getRealTimeSportBets: PropTypes.func,
    /** Redux action to set real time sport bets filters */
    setRealTimeSportBetsFilters: PropTypes.func,
    /** Redux state property, real time sport bets filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
}

const mapDispatchToProps = dispatch => (
    {
        setRealTimeSportBetsFilters: filters => {
            dispatch(setRealTimeSportBetsFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        globalProjectId: state.common.globalProjectId,
        filters: state.betHistory.realTimeBets.sportBets.filters
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
