import axios from "axios";
import ApiUrls from "constants/api.constants";
import Methods from "constants/methods.constants";

export const getVouchersConfigs = () => {
    return axios({
        url: `${ApiUrls.GET_PROJECT_VOUCHERS_CONFIGS}`,
        method: Methods.GET,
    }).then(({data: {value}}) => {
        return value
    });
}

export const saveVouchersConfigs = data => {
    return axios({
        url: `${ApiUrls.SAVE_PROJECT_VOUCHERS_CONFIGS}`,
        method: Methods.POST,
        data: {
            ...data,
        },
    }).then(({data: {value, message}}) => {
        return {value, message};
    });
}
