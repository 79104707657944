import {
    SET_BET_HISTORY_ACTION_BEFORE,
    SET_BET_HISTORY_ACTION_FINISH,
    SET_RETAIL_PENDING_BET_HISTORY,
    SET_RETAIL_PENDING_BET_HISTORY_SORTING,
    SET_RETAIL_PENDING_BET_HISTORY_FILTERS,
    SET_RETAIL_SETTLED_BET_HISTORY,
    SET_RETAIL_SETTLED_BET_HISTORY_SORTING,
    SET_RETAIL_SETTLED_BET_HISTORY_FILTERS,
    SET_ONLINE_BET_HISTORY,
    SET_ONLINE_BET_HISTORY_LOADING,
    SET_ONLINE_BET_HISTORY_SORTING,
    SET_ONLINE_BET_HISTORY_FILTERS,
    SET_ONLINE_BETS_TOTALS,
    SET_AGGREGATED_BET_HISTORY,
    SET_AGGREGATED_BET_HISTORY_SORTING,
    SET_AGGREGATED_BET_HISTORY_FILTERS,
    SET_AGGREGATED_BET_HISTORY_LOADING,
    SET_AGGREGATED_BETS_TOTALS,
    SET_RETAIL_SETTLED_BET_TOTALS,
    SET_REAL_TIME_SPORT_BETS,
    SET_REAL_TIME_SPORT_BETS_SORTING,
    SET_REAL_TIME_SPORT_BETS_FILTERS,
    SET_REAL_TIME_SPORT_BET_DETAILS,
    SET_AGGREGATED_BETS_TOTALS_BEFORE,
    SET_AGGREGATED_BETS_TOTALS_FINISH,
    SET_REAL_TIME_CASINO_BETS,
    SET_REAL_TIME_CASINO_BETS_SORTING,
    SET_REAL_TIME_CASINO_BETS_FILTERS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_BET_HISTORY_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_BET_HISTORY_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_RETAIL_PENDING_BET_HISTORY:

            return {
                ...state,
                retail: {
                    ...state.retail,
                    pendings: {
                        ...state.retail.pendings,
                        bets: !payload.add ? payload.bets.item2 : state.retail.pendings.bets.concat(payload.bets.item2),
                        total: payload.bets.item1
                    }
                }
            }
        case SET_RETAIL_PENDING_BET_HISTORY_FILTERS:

            return {
                ...state,
                retail: {
                    ...state.retail,
                    pendings: {
                        ...state.retail.pendings,
                        sorting: {
                            ...state.retail.pendings.sorting,
                            page: payload.keepPage ? state.retail.pendings.sorting.page : 1
                        },
                        filters: payload.filters,
                    }
                }
            };
        case SET_RETAIL_PENDING_BET_HISTORY_SORTING:

            return {
                ...state,
                retail: {
                    ...state.retail,
                    pendings: {
                        ...state.retail.pendings,
                        sorting: payload.sorting,
                    }
                }
            };
        case SET_RETAIL_SETTLED_BET_HISTORY:

            return {
                ...state,
                retail: {
                    ...state.retail,
                    settled: {
                        ...state.retail.settled,
                        bets: !payload.add ? payload.bets.item2 : state.retail.settled.bets.concat(payload.bets.item2),
                        total: payload.bets.item1
                    }
                }
            }
        case SET_RETAIL_SETTLED_BET_TOTALS:

            return {
                ...state,
                retail: {
                    ...state.retail,
                    settled: {
                        ...state.retail.settled,
                        totals: payload.totals
                    }
                }
            }
        case SET_RETAIL_SETTLED_BET_HISTORY_FILTERS:

            return {
                ...state,
                retail: {
                    ...state.retail,
                    settled: {
                        ...state.retail.settled,
                        sorting: {
                            ...state.retail.settled.sorting,
                            page: payload.keepPage ? state.retail.settled.sorting.page : 1
                        },
                        filters: payload.filters,
                    }
                }
            };
        case SET_RETAIL_SETTLED_BET_HISTORY_SORTING:

            return {
                ...state,
                retail: {
                    ...state.retail,
                    settled: {
                        ...state.retail.settled,
                        sorting: payload.sorting,
                    }
                }
            };
        case SET_ONLINE_BET_HISTORY:

            return {
                ...state,
                online: {
                    ...state.online,
                    sport: {
                        ...state.online.sport,
                        bets: !payload.add ? payload.bets.item2 : state.online.sport.bets.concat(payload.bets.item2),
                        total: payload.bets.item1
                    }
                }
            }
        case SET_ONLINE_BET_HISTORY_LOADING:
            return {
                ...state,
                online: {
                    ...state.online,
                    sport: {
                        ...state.online.sport,
                        loading: payload.loading
                    }
                }
            }
        case SET_ONLINE_BET_HISTORY_FILTERS:

            return {
                ...state,
                online: {
                    ...state.online,
                    sport: {
                        ...state.online.sport,
                        sorting: {
                            ...state.online.sport.sorting,
                            page: 1
                        },
                        filters: payload.filters,
                    }
                }
            };
        case SET_ONLINE_BET_HISTORY_SORTING:

            return {
                ...state,
                online: {
                    ...state.online,
                    sport: {
                        ...state.online.sport,
                        sorting: payload.sorting,
                    }
                }
            };
        case SET_AGGREGATED_BET_HISTORY:
            return {
                ...state,
                online: {
                    ...state.online,
                    aggregated: {
                        ...state.online.aggregated,
                        bets: !payload.add ? payload.bets.item2 : state.online.aggregated.bets.concat(payload.bets.item2),
                        total: payload.bets.item1
                    }
                }
            }
        case SET_AGGREGATED_BET_HISTORY_LOADING:
            return {
                ...state,
                online: {
                    ...state.online,
                    aggregated: {
                        ...state.online.aggregated,
                        loading: payload.loading
                    }
                }
            }
        case SET_AGGREGATED_BET_HISTORY_FILTERS:
            return {
                ...state,
                online: {
                    ...state.online,
                    aggregated: {
                        ...state.online.aggregated,
                        sorting: {
                            ...state.online.aggregated.sorting,
                            page: 1
                        },
                        filters: payload.filters,
                    }
                }
            };
        case SET_AGGREGATED_BET_HISTORY_SORTING:
            return {
                ...state,
                online: {
                    ...state.online,
                    aggregated: {
                        ...state.online.aggregated,
                        sorting: payload.sorting,
                    }
                }
            };
        case SET_AGGREGATED_BETS_TOTALS:
            return {
                ...state,
                online: {
                    ...state.online,
                    aggregated: {
                        ...state.online.aggregated,
                        totals: {
                            ...state.online.aggregated.totals,
                            data: payload.totals,
                        },
                    }
                }
            };
        case SET_AGGREGATED_BETS_TOTALS_BEFORE:
            return {
                ...state,
                online: {
                    ...state.online,
                    aggregated: {
                        ...state.online.aggregated,
                        totals: {
                            ...state.online.aggregated.totals,
                            loading: true,
                        }
                    }
                }
            };
        case SET_AGGREGATED_BETS_TOTALS_FINISH:
            return {
                ...state,
                online: {
                    ...state.online,
                    aggregated: {
                        ...state.online.aggregated,
                        totals: {
                            ...state.online.aggregated.totals,
                            loading: false,
                        }
                    }
                }
            };
        case SET_ONLINE_BETS_TOTALS:
            return {
                ...state,
                online: {
                    ...state.online,
                    sport: {
                        ...state.online.sport,
                        totals: payload.totals,
                    }
                }
            };
        case SET_REAL_TIME_SPORT_BETS:
            return {
                ...state,
                realTimeBets: {
                    ...state.realTimeBets,
                    sportBets: {
                        ...state.realTimeBets.sportBets,
                        bets: !payload.add ? payload.bets.bets.item2 : state.realTimeBets.sportBets.bets.concat(payload.bets.bets.item2),
                        total: payload.bets.bets.item1,
                        totals: payload.bets.totals
                    }
                }
            }
        case SET_REAL_TIME_SPORT_BETS_SORTING:
            return {
                ...state,
                realTimeBets: {
                    ...state.realTimeBets,
                    sportBets: {
                        ...state.realTimeBets.sportBets,
                        sorting: payload.sorting
                    }
                }
            }
        case SET_REAL_TIME_SPORT_BETS_FILTERS:
            return {
                ...state,
                realTimeBets: {
                    ...state.realTimeBets,
                    sportBets: {
                        ...state.realTimeBets.sportBets,
                        sorting: {
                            ...state.realTimeBets.sorting,
                            page: 1
                        },
                        filters: payload.filters,
                    },
                }
            }
        case SET_REAL_TIME_SPORT_BET_DETAILS:
            return {
                ...state,
                realTimeBets: {
                    ...state.realTimeBets,
                    sportBets: {
                        ...state.realTimeBets.sportBets,
                        details: {
                            ...state.realTimeBets.sportBets.details,
                            [payload.id]: payload.details
                        }
                    }
                }
            }
        case SET_REAL_TIME_CASINO_BETS:
            return {
                ...state,
                realTimeBets: {
                    ...state.realTimeBets,
                    casinoBets: {
                        ...state.realTimeBets.casinoBets,
                        bets: !payload.add ? payload.bets.bets.item2 : state.realTimeBets.casinoBets.bets.concat(payload.bets.bets.item2),
                        total: payload.bets.bets.item1,
                        totals: payload.bets.totals
                    }
                }
            }
        case SET_REAL_TIME_CASINO_BETS_SORTING:
            return {
                ...state,
                realTimeBets: {
                    ...state.realTimeBets,
                    casinoBets: {
                        ...state.realTimeBets.casinoBets,
                        sorting: payload.sorting
                    }
                }
            }
        case SET_REAL_TIME_CASINO_BETS_FILTERS:
            return {
                ...state,
                realTimeBets: {
                    ...state.realTimeBets,
                    casinoBets: {
                        ...state.realTimeBets.casinoBets,
                        sorting: {
                            ...state.realTimeBets.sorting,
                            page: 1
                        },
                        filters: payload.filters,
                    },
                }
            }
        default:
            return state;
    }
};
