import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Form, Switch, Menu } from "antd";

import Select from "components/common/select";

import Confirmation from "components/common/confirmation";
import Dropdown from "components/common/dropdown";
import Tooltip from "components/common/tooltip";
import MainDashboardLayout from "components/layouts/main";

import TranslationCreateComponent from "./translation-create.component";

import {
    getRetailTranslations,
    getBOTranslations,
    saveRetailTranslations,
    saveBOTranslation,
    resetTranslation,
    publishRetailTranslations,
    publishBOTranslations,
    unpublishRetailTranslations,
    unpublishWidgetTranslations,
    getTranslationGroups,
    setTranslationsSystemDefault,
    getWidgetTranslations,
    saveWidgetTranslation,
    publishWidgetTranslations,
    getAgentTranslations,
    saveAgentTranslation,
    publishAgentTranslations
} from "store/actions/dashboard/cms/translations/translations.action";

import { getSystemAvailableLanguages } from "store/actions/dashboard/settings/systemLanguages/systemLanguages.action";
import { getCompanyAvailableLanguages } from 'store/actions/dashboard/common/common.action';
import translationGroupType from "types/translation/group.type";
import translationType from "types/translation/translation.type";

import { isMobile, isFranchisingMode } from "utils/common";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { TRANSLATION_SOURCE_TYPE, TRANSLATION_TYPE, BO_GROUP_ID, WIDGET_GROUP_ID, AGENT_GROUP_ID } from "constants/translation.constants";
import TranslationsTable from "pages/cms/translations/translations-table.component";

/** Translations Page Component */
const TranslationsComponent = ({
    getRetailTranslations,
    getBOTranslations,
    getWidgetTranslations,
    getAgentTranslations,
    saveWidgetTranslation,
    saveRetailTranslations,
    saveBOTranslation,
    saveAgentTranslation,
    resetTranslation,
    publishRetailTranslations,
    publishBOTranslations,
    publishWidgetTranslations,
    publishAgentTranslations,
    unpublishRetailTranslations,
    unpublishWidgetTranslations,
    getTranslationGroups,
    setTranslationsSystemDefault,
    isLoading,
    isPublishing,
    isUnpublishing,
    translationGroups,
    translations,
    systemDefault,
    getSystemAvailableLanguages,
    getCompanyAvailableLanguages,
    systemAvailableLanguages,
    companyAvailableLanguages,
    isCompanyAvailableLanguagesLoading,
    isSystemAvailableLanguagesLoading,
    translationType
}) => {
    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState("");

    /** State to show/hide company creation popup */
    const [showCreatePopup, setShowCreatePopup] = useState(false);

    /** State to show/hide unpublish confirmation popup */
    const [showUnpublishPopup, setShowUnpublishPopup] = useState(false);

    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedGroupID, setSelectedGroupID] = useState(null);

    const isSystemDefault = [TRANSLATION_TYPE.BO, TRANSLATION_TYPE.WIDGET].includes(translationType) || systemDefault;

    const groups = translationGroups[translationType];

    /** Load translation groups */
    useEffect(() => {
        getTranslationGroups(translationType);
    }, [])

    /** Load system available languages */
    useEffect(() => {
        if (isSystemDefault && Object.keys(systemAvailableLanguages).length === 0) {
            getSystemAvailableLanguages()
        }
    }, [systemAvailableLanguages])

    /** Load company available languages */
    useEffect(() => {
        if (!isSystemDefault && Object.keys(companyAvailableLanguages).length === 0) {
            getCompanyAvailableLanguages()
        }
    }, [companyAvailableLanguages])

    /** Reset selected group Id */
    useEffect(() => {
        return () => {
            setSelectedGroupID(null);
        }
    }, [])

    /** Set languages */
    useEffect(() => {
        if (isSystemDefault && Object.keys(systemAvailableLanguages).length > 0) {
            setSelectedLanguages(Object.keys(systemAvailableLanguages).splice(0, isMobile() ? 1 : 2))
        } else if (!isSystemDefault && Object.keys(companyAvailableLanguages).length > 0) {
            setSelectedLanguages(Object.keys(companyAvailableLanguages).splice(0, isMobile() ? 1 : 2));
        }
    }, [companyAvailableLanguages, systemAvailableLanguages, isSystemDefault])

    /** Load translation on resource change */
    useEffect(() => {
        if (selectedGroupID && selectedLanguages.length > 0) {
            handleResourceDropdownChange();
        }
    }, [selectedGroupID, selectedLanguages])

    /** Set group value */
    useEffect(() => {
        if (
            ((isSystemDefault && Object.keys(systemAvailableLanguages).length) ||
                (!isSystemDefault && Object.keys(companyAvailableLanguages).length)) &&
            selectedLanguages.length > 0 && groups.length > 0
        ) {
            const grId = selectedGroupID || groups[0].id;
            setSelectedGroupID(grId);
        }
    }, [groups, selectedLanguages])


    /** Get languages to edit
       * @function
       * @returns {object}
       * @memberOf TranslationsComponent
   */
    const getLanguages = useCallback(() => (
        isCompanyAvailableLanguagesLoading || isSystemAvailableLanguagesLoading || isSystemDefault ? systemAvailableLanguages : companyAvailableLanguages
    ), [companyAvailableLanguages, isCompanyAvailableLanguagesLoading, isSystemAvailableLanguagesLoading, isSystemDefault, systemAvailableLanguages]);

    /** Get current group and subgroup id
         * @function
         * @returns {object}
         * @memberOf TranslationsComponent
     */
    const getGroupAndSubGroupId = useCallback(() => {
        let groupId = null;
        let subGroupId = null;

        if (translationType === TRANSLATION_TYPE.RETAIL) {
            if (groups.map(gr => gr.id).includes(selectedGroupID)) {
                groupId = selectedGroupID;
            } else {
                groups.forEach(gr => {
                    if (!gr.subGroups || gr.subGroups.length === 0) return true;
                    if ((gr.subGroups || []).map(s => s.id).includes(selectedGroupID)) {
                        subGroupId = selectedGroupID,
                            groupId = gr.id;
                        return false;
                    }
                })
            }
        } else if (translationType === TRANSLATION_TYPE.WIDGET) {
            groupId = WIDGET_GROUP_ID;
            subGroupId = selectedGroupID;
        } else if (translationType === TRANSLATION_TYPE.AGENT) {
            groupId = AGENT_GROUP_ID;
            subGroupId = selectedGroupID;
        } else {
            groupId = BO_GROUP_ID;
            subGroupId = selectedGroupID;
        }

        return { groupId, subGroupId };
    }, [groups, selectedGroupID, translationType])

    /** Fires on groups dropdown change
       * @function
       * @memberOf TranslationsComponent
   */
    const handleResourceDropdownChange = () => {
        const { groupId, subGroupId } = getGroupAndSubGroupId();
        setSearchValue("");

        if (translationType === TRANSLATION_TYPE.RETAIL) {
            getRetailTranslations(groupId, subGroupId, selectedLanguages, systemDefault);
        } else if (translationType === TRANSLATION_TYPE.WIDGET) {
            getWidgetTranslations(subGroupId, selectedLanguages)
        } else if (translationType === TRANSLATION_TYPE.AGENT) {
            getAgentTranslations(subGroupId, selectedLanguages)
        } else {
            getBOTranslations(subGroupId, selectedLanguages);
        }
    }

    /** Make translations data
       * @function
       * @returns {array}
       * @memberOf TranslationsComponent
   */

    /** Render table column header
     * @function
     * @param {string} lang - language for column
     * @param {number} index - column index
     * @returns {JSX}
     * @memberOf TranslationsComponent
     */
    const renderTranslationColumnTitle = useCallback((lang, index) => {
        let languages = Object.keys(getLanguages()).filter(l => !selectedLanguages.includes(l) || l === lang)

        return (
            <Dropdown
                getPopupContainer={trigger => trigger.parentElement}
                menu={{
                    selectedKeys: [],
                    className: "rt--table-header-dropdown rt--table-header-dropdown-translations",
                    placement: "bottomLeft",
                    trigger: ['click'],
                    items: (
                        languages.map(l => (
                            {
                                key: l,
                                onClick: e => {
                                    const update = selectedLanguages.map((l, i) => i === index ? e.key : l);
                                    setSelectedLanguages(update);
                                },
                                label: t("backoffice.languages." + l)
                            }
                        ))
                    )
                }}
                disabled={false}
            >
                <div
                    className="rt--table-header-dropdown-content rt--flex-equal rt--flex rt--align-center rt--cursor-pointer"
                >
                    <i className="icon-translations rt--font-bigest" />
                    <span className="rt--pl-8 rt--pr-4 rt--title rt--font-normal rt--font-bold">{t("backoffice.languages." + selectedLanguages[index])}</span>
                    <i className="icon-down rt--font-bigest" />

                    {
                        hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.MODIFY }) && (
                            <div
                                className="rt--table-header-dropdown-content-actions rt--flex-inline rt--align-center rt--justify-end"
                            >
                                {
                                    (
                                        !systemDefault &&
                                        (translationType === TRANSLATION_TYPE.RETAIL || translationType === TRANSLATION_TYPE.WIDGET)
                                    ) && (
                                        <div className="rt--table-header-dropdown-content-actions-item rt--flex rt--align-center rt--justify-center rt--mr-4"
                                             onClick={e => {
                                                 e.stopPropagation();
                                                 setShowUnpublishPopup(selectedLanguages[index])
                                             }}
                                        >
                                            <Tooltip
                                                title={t('backoffice.translations.unpublish')}
                                            >
                                                <i className="icon-unpublish rt--font-bigest" />
                                            </Tooltip>
                                        </div>
                                    )
                                }

                                <div className="rt--table-header-dropdown-content-actions-item rt--flex rt--align-center rt--justify-center"
                                     onClick={e => {
                                        e.stopPropagation();
                                        if (translationType === TRANSLATION_TYPE.RETAIL) {
                                            publishRetailTranslations(systemDefault, selectedLanguages[index])
                                        } else if (translationType === TRANSLATION_TYPE.WIDGET) {
                                            publishWidgetTranslations(systemDefault, selectedLanguages[index])
                                        } else if (translationType === TRANSLATION_TYPE.AGENT) {
                                            publishAgentTranslations(systemDefault, selectedLanguages[index])
                                        } else {
                                            publishBOTranslations(selectedLanguages[index])
                                        }
                                     }}
                                >
                                    <Tooltip
                                        title={t('backoffice.translations.publish')}
                                    >
                                        <i className="icon-publish rt--font-bigest" />
                                    </Tooltip>
                                </div>


                            </div>
                        )
                    }

                </div>
            </Dropdown>
        )
    }, [getLanguages, publishBOTranslations, publishRetailTranslations, publishWidgetTranslations, publishAgentTranslations, selectedLanguages, systemDefault, t, translationType])

    /** Render table column header
     * @function
     * @returns {JSX}
     * @memberOf TranslationsComponent
     */
    const renderSystemDefaultSwitcher = () => (
        <div>
            <div className="rt--switcher rt--flex-inline rt--align-center">
                <Form.Item
                    className='rt--form-item-without-margin'
                >
                    <Switch
                        checked={systemDefault}
                        onChange={value => handleSystemDefaultChange(value)}
                    />
                </Form.Item>
                <div className='rt--flex rt--align-center'>
                    <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.translations.systemDefaultTranslations')}</label>
                </div>
            </div>
        </div>
    )

    /** Render languages select for mobile
     * @function
     * @returns {JSX}
     * @memberOf TranslationsComponent
     */

    const renderLanguagesDropdown = () => {
        const languages = Object.keys(getLanguages());
        return (
            <div className="rt--mb-12">
                <Form.Item
                    className='rt--form-item-without-margin'
                >
                    <Select
                        options={languages.map(lang => (
                            { value: lang, text: t("backoffice.languages." + lang) }
                        ))}
                        placeholder={`${t("backoffice.common.select")} ${t("backoffice.translations.language")}`}
                        onChange={e => setSelectedLanguages([e])}
                        value={selectedLanguages[0] ? selectedLanguages[0] : languages[0]}
                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                    />
                </Form.Item>

            </div>
        )
    }

    const data = useMemo(() => {
        let res = [...translations.filter(tr => !searchValue || tr.key.toLowerCase().includes(searchValue.toLowerCase()))];
        res = res.map(tr => {
            const obj = {};
            if (tr.translations) {
                tr.translations.forEach(t => {
                    obj[t.languageCode] = {
                        text: t.text,
                        source: t.source
                    }
                })
            }

            return {
                ...tr,
                translations: obj
            }
        })
        return res;
    }, [searchValue, translations]);

    /** Make columns array depend on languages list
       * @function
       * @param {string} value - selected value
       * @returns {array}
       * @memberOf TranslationsComponent
   */

    const columns = useMemo(() => {
        const res = [
            {
                title: t("backoffice.translations.keys"),
                dataIndex: "key",
                width: "25%",
                mobileLevel: 1
            },
            {
                title: t('backoffice.translations.systemDefault'),
                dataIndex: 'text',
                short: !isMobile(),
                middle: isMobile(),
                width: "25%",
                mobileLevel: 2
            }
        ];
        selectedLanguages.forEach((lang, index) => {
            res.push({
                title: renderTranslationColumnTitle(lang, index),
                dataIndex: "translations." + lang + ".text",
                render: (value, record) => record?.translations?.[lang.toUpperCase()]?.text ?? "",
                editable: hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.MODIFY }),
                onEdit: (value, record) => {
                    const translation = {
                        key: record.key,
                        subGroupId: getGroupAndSubGroupId().subGroupId,
                        languageCode: lang,
                        text: value
                    }

                    if ([TRANSLATION_TYPE.RETAIL, TRANSLATION_TYPE.WIDGET].includes(translationType)) {
                        translation.isSystemDefault = isSystemDefault;
                        translation.groupId = getGroupAndSubGroupId().groupId;
                    }

                    if (translationType === TRANSLATION_TYPE.RETAIL) {
                        saveRetailTranslations(translation)
                    } else if (translationType === TRANSLATION_TYPE.WIDGET) {
                        saveWidgetTranslation(translation)
                    } else if (translationType === TRANSLATION_TYPE.AGENT) {
                        saveAgentTranslation(translation)
                    } else {
                        saveBOTranslation(translation)
                    }
                },
                editableLabel: () => selectedLanguages[0] ? getLanguages()[selectedLanguages[0]] : t("backoffice.translations.translation"),
                editableTitle: () => t("backoffice.translations.translation"),
                maxLength: 100,
                extraButtons: [{
                    icon: "icon-reload",
                    title: t("backoffice.translations.resetToDefault"),
                    disabled: record => (
                        !hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.MODIFY }) ||
                        record?.translations?.[lang.toUpperCase()]?.source !== TRANSLATION_SOURCE_TYPE.OVERRIDEN
                    ),
                    onClick: record => resetTranslation({
                        key: record.key,
                        groupId: getGroupAndSubGroupId().groupId,
                        subGroupId: getGroupAndSubGroupId().subGroupId,
                        languageCode: lang
                    })
                }],
                width: "25%",
                mobileLevel: 3,
                mobileTitle: selectedLanguages[0] ? getLanguages()[selectedLanguages[0]] : t("backoffice.translations.translation"),
                mobileRender: (value, record) => {
                    const inputValue = record?.translations?.[lang.toUpperCase()]?.text ?? "";

                    return (
                        <Tooltip
                            title={inputValue}
                            trigger={["hover", "click"]}
                            placement="top"
                            enableMobile={true}
                        >
                            {inputValue}
                        </Tooltip>
                    )
                }
            })
        })

        return res
    }, [
        getGroupAndSubGroupId,
        getLanguages,
        isSystemDefault,
        renderTranslationColumnTitle,
        resetTranslation,
        saveBOTranslation,
        saveRetailTranslations,
        saveWidgetTranslation,
        saveAgentTranslation,
        selectedLanguages,
        t,
        translationType
    ])

    /** Fires on system default switcher change
         * @function
         * @param {boolean} value
         * @memberOf TranslationsComponent
     */
    const handleSystemDefaultChange = value => {
        if (value && Object.keys(systemAvailableLanguages).length === 0) {
            getSystemAvailableLanguages()
        } else if (!value && Object.keys(companyAvailableLanguages).length === 0) {
            getCompanyAvailableLanguages();
        }
        setTranslationsSystemDefault(value);
    }

    /** Close unpublishing confirmation popup after unpublish */
    useEffect(() => {
        if (!isUnpublishing) {
            setShowUnpublishPopup(false);
        }
    }, [isUnpublishing])

    return (
        <MainDashboardLayout
            header={
                {
                    search: {
                        onSearch: value => setSearchValue(value),
                        placeholder: t("backoffice.common.search")
                    },

                    buttons: [
                        {
                            icon: "icon-publish",
                            type: "primary",
                            onClick: () => {
                                translationType === TRANSLATION_TYPE.RETAIL
                                    ? publishRetailTranslations(systemDefault)
                                    : translationType === TRANSLATION_TYPE.WIDGET
                                        ? publishWidgetTranslations(systemDefault)
                                            : translationType === TRANSLATION_TYPE.AGENT
                                            ? publishAgentTranslations() : publishBOTranslations()
                            },
                            text: t("backoffice.translations.publish"),
                            enabled: hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.MODIFY }),
                            disabled: isPublishing
                        },

                        {
                            icon: "icon-unpublish",
                            type: "danger",
                            className: 'rt--button-secondary',
                            onClick: () => setShowUnpublishPopup(true),
                            text: t("backoffice.translations.unpublish"),
                            enabled: !systemDefault && (translationType === TRANSLATION_TYPE.RETAIL || translationType === TRANSLATION_TYPE.WIDGET) && hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.MODIFY }),
                            disabled: isUnpublishing
                        },

                        {
                            icon: "icon-plus",
                            type: "primary",
                            onClick: () => setShowCreatePopup(true),
                            text: t("backoffice.translations.createTranslation"),
                            enabled: isSystemDefault && hasPermission({ resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.CREATE })
                        }
                    ],

                    breadcrumbs: {
                        items: [
                            {
                                title: (
                                    translationType === TRANSLATION_TYPE.RETAIL
                                        ? t('backoffice.menu.retailTranslations')
                                        : translationType === TRANSLATION_TYPE.WIDGET
                                            ? t('backoffice.menu.widgetTranslations')
                                            : translationType === TRANSLATION_TYPE.AGENT
                                                ? t('backoffice.menu.agentTranslations')
                                                : t('backoffice.menu.boTranslations')
                                )
                            }
                        ]
                    }
                }
            }

            dropdowns={
                [
                    {
                        placeholder: t("backoffice.translations.resource"),
                        value: selectedGroupID,
                        onChange: value => setSelectedGroupID(value),
                        items: groups.map(gr => ({
                            key: gr.id,
                            value: gr.name === "Agents" && isFranchisingMode() ? "Betshop Owners" : gr.name,
                            subs: (gr.subGroups || []).map(g => ({
                                key: g.id,
                                value: g.name,
                            }))
                        })),
                        search: translationType === TRANSLATION_TYPE.BO
                    }
                ]
            }

            extraContent={
                {
                    content: (
                        <Fragment>
                            {isMobile() && renderLanguagesDropdown()}
                            {[TRANSLATION_TYPE.RETAIL, TRANSLATION_TYPE.WIDGET].includes(translationType) && renderSystemDefaultSwitcher()}
                        </Fragment>
                    )
                }
            }
        >
            <div className="rt--translations-table">
                <TranslationsTable
                    loading={isLoading || isPublishing || isUnpublishing}
                    columns={columns}
                    data={data}
                />
                {/*<Table*/}
                {/*    loading={isLoading || isPublishing || isUnpublishing}*/}
                {/*    columns={makeColumns()}*/}
                {/*    data={makeTranslationData()}*/}
                {/*    noPagination={true}*/}
                {/*    uniqueKey="key"*/}
                {/*    disableFullView={true}*/}
                {/*    noAction={true}*/}
                {/*/>*/}
            </div>


            {
                showCreatePopup && (
                    <TranslationCreateComponent
                        onClose={() => setShowCreatePopup(false)}
                        groupId={
                            translationType === TRANSLATION_TYPE.RETAIL
                                ? getGroupAndSubGroupId()?.groupId
                                : translationType === TRANSLATION_TYPE.WIDGET
                                    ? WIDGET_GROUP_ID
                                    : translationType === TRANSLATION_TYPE.AGENT
                                        ? AGENT_GROUP_ID
                                        : BO_GROUP_ID
                        }
                        subGroupId={getGroupAndSubGroupId()?.subGroupId}
                        languageCodes={selectedLanguages}
                        translationType={translationType}
                    />
                )
            }

            {
                showUnpublishPopup && (
                    <Confirmation
                        title={t('backoffice.translations.unpublish')}
                        message={t('backoffice.translations.unpublishMessage')}
                        onOk={() => {
                            if (translationType === TRANSLATION_TYPE.RETAIL) {
                                unpublishRetailTranslations(showUnpublishPopup !== true ? showUnpublishPopup : null)
                            } else if (translationType === TRANSLATION_TYPE.WIDGET) {
                                unpublishWidgetTranslations(showUnpublishPopup !== true ? showUnpublishPopup : null)
                            }
                        }}
                        onCancel={() => setShowUnpublishPopup(false)}
                        isVisible={true}
                    />
                )
            }
        </MainDashboardLayout>
    )
};

/** TranslationsComponent propTypes
 * PropTypes
*/
TranslationsComponent.propTypes = {
    /** Redux action to get translations */
    getRetailTranslations: PropTypes.func,
    /** Redux action to get BO translations */
    getBOTranslations: PropTypes.func,
    /** Redux action to get Widget translations */
    getWidgetTranslations: PropTypes.func,
    /** Redux action to get Agent translations */
    getAgentTranslations: PropTypes.func,
    /** Redux action to save translations */
    saveRetailTranslations: PropTypes.func,
    /** Redux action to save BO translations */
    saveBOTranslation: PropTypes.func,
    /** Redux action to save Widget translations */
    saveWidgetTranslation: PropTypes.func,
    /** Redux action to save Agent translations */
    saveAgentTranslation: PropTypes.func,
    /** Redux action to reset translations */
    resetTranslation: PropTypes.func,
    /** Redux action to publish translations */
    publishRetailTranslations: PropTypes.func,
    /** Redux action to publish BO translations */
    publishBOTranslations: PropTypes.func,
    /** Redux action to publish Widget translations */
    publishWidgetTranslations: PropTypes.func,
    /** Redux action to publish Agent translations */
    publishAgentTranslations: PropTypes.func,
    /** Redux action to unpublish retail translations */
    unpublishRetailTranslations: PropTypes.func,
    /** Redux action to unpublish widget translations */
    unpublishWidgetTranslations: PropTypes.func,
    /** Redux action to get translation groups */
    getTranslationGroups: PropTypes.func,
    /** Redux action to set system default translation */
    setTranslationsSystemDefault: PropTypes.func,
    /** Redux state property, is true when loading translations */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when publishing translations */
    isPublishing: PropTypes.bool,
    /** Redux state property, is true when unpublishing translation */
    isUnpublishing: PropTypes.bool,
    /** Redux state property, translation groups */
    translationGroups: PropTypes.objectOf(PropTypes.arrayOf(translationGroupType)),
    /** Redux state property, translations */
    translations: PropTypes.arrayOf(translationType),
    /** Redux state property, is true when system default translations is enabled */
    systemDefault: PropTypes.bool,
    /** Redux state, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux action to get system available languages */
    getSystemAvailableLanguages: PropTypes.func,
    /** Redux action to get company available languages */
    getCompanyAvailableLanguages: PropTypes.func,
    /** Redux state property, represents the object of available system languages */
    systemAvailableLanguages: PropTypes.object,
    /** Redux state property, represents the object of company available  languages */
    companyAvailableLanguages: PropTypes.object,
    /** Redux state property, is true when company available languages are loading*/
    isCompanyAvailableLanguagesLoading: PropTypes.bool,
    /** Redux state property, is true when system available languages are loading*/
    isSystemAvailableLanguagesLoading: PropTypes.bool,
    /** Translation type (retail/bo) */
    translationType: PropTypes.oneOf(Object.values(TRANSLATION_TYPE))
};

const mapDispatchToProps = dispatch => ({
    getRetailTranslations: (groupId, subGroupId, languageCodes, isSystemDefault) => {
        dispatch(getRetailTranslations(groupId, subGroupId, languageCodes, isSystemDefault));
    },
    getBOTranslations: (subGroupId, languageCodes) => {
        dispatch(getBOTranslations(subGroupId, languageCodes));
    },
    getWidgetTranslations: (subGroupId, languageCodes) => {
        dispatch(getWidgetTranslations(subGroupId, languageCodes));
    },
    getAgentTranslations: (subGroupId, languageCodes) => {
        dispatch(getAgentTranslations(subGroupId, languageCodes));
    },
    saveRetailTranslations: translation => {
        dispatch(saveRetailTranslations(translation))
    },
    saveBOTranslation: translation => {
        dispatch(saveBOTranslation(translation))
    },
    saveWidgetTranslation: translation => {
        dispatch(saveWidgetTranslation(translation))
    },
    saveAgentTranslation: translation => {
        dispatch(saveAgentTranslation(translation))
    },
    resetTranslation: translation => {
        dispatch(resetTranslation(translation))
    },
    getTranslationGroups: translationType => {
        dispatch(getTranslationGroups(translationType))
    },
    setTranslationsSystemDefault: systemDefault => {
        dispatch(setTranslationsSystemDefault(systemDefault))
    },
    publishBOTranslations: language => {
        dispatch(publishBOTranslations(language))
    },
    publishRetailTranslations: (isSystemDefault, language) => {
        dispatch(publishRetailTranslations(isSystemDefault, language))
    },
    publishWidgetTranslations: (isSystemDefault, language) => {
        dispatch(publishWidgetTranslations(isSystemDefault, language))
    },
    publishAgentTranslations: language => {
        dispatch(publishAgentTranslations(language))
    },
    unpublishRetailTranslations: languageCode => {
        dispatch(unpublishRetailTranslations(languageCode))
    },
    unpublishWidgetTranslations: languageCode => {
        dispatch(unpublishWidgetTranslations(languageCode))
    },
    getSystemAvailableLanguages: () => {
        dispatch(getSystemAvailableLanguages())
    },
    getCompanyAvailableLanguages: () => {
        dispatch(getCompanyAvailableLanguages())
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.translations.isLoading,
        isPublishing: state.translations.isPublishing,
        isUnpublishing: state.translations.isUnpublishing,
        translations: state.translations.translations,
        translationGroups: state.translations.groups,
        systemDefault: state.translations.systemDefault,
        systemAvailableLanguages: state.systemLanguages.systemAvailableLanguages,
        companyAvailableLanguages: state.common.availableLanguages.availableLanguages,
        isCompanyAvailableLanguagesLoading: state.common.availableLanguages.isLoading,
        isSystemAvailableLanguagesLoading: state.systemLanguages.isAvailableLoading,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TranslationsComponent);
