import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Col, Form, message, Row, Spin } from "antd";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";
import NumericInput from "components/common/numericInput";

import { getVouchersConfigs, saveVouchersConfigs } from "./api";

const Vouchers = ({
    onTabChange
}) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [vouchersConfigs, setVouchersConfigs] = useState(false);

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_TERMINALS, action: PERMISSION_ACTION.MODIFY });

    useEffect(() => {
        setIsLoading(true)
        getVouchersConfigs()
            .then(value => {
                setVouchersConfigs(value);
                setFieldsValue({
                    expirationDuration: value.expirationDuration
                })
            })
            .finally(value => {
                setIsLoading(false)
            })
    }, [])

    const onFormValuesChange = (changed, formValues) => {
        const initialValues = {}, values = {};

        initialValues.expirationDuration = vouchersConfigs.expirationDuration;
        values.expirationDuration = formValues.expirationDuration;

        setIsFormTouched(isFormChanged(initialValues, values));
    }

    const handleForm = () => {
        validateFields()
            .then(data => {
                setIsSaving(true);
                const d = {
                    expirationDuration: data.expirationDuration
                }
                saveVouchersConfigs(d)
                    .then(({value, message: msg}) => {
                        message.success(msg)
                        setVouchersConfigs(value);
                        setIsFormTouched(false);
                    })
                    .finally(() => {
                        setIsSaving(false)
                    })
                
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasModifyPermission,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={onFormValuesChange}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <h4 className='rt--form-section-title rt--title rt--mb-24 rt--mt-8 rt--font-bold rt--font-biger'>
                                {
                                     t("backoffice.projects.configuration")
                                }
                            </h4>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.projects.expirationDuration')}
                                name="expirationDuration"
                                className={"rt--general-form-item" + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.expirationDuration')}`}
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 999, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                            >
                                <NumericInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.expirationDuration')}`}
                                    maxLength={4}
                                    disabled={!hasModifyPermission}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

export default Vouchers;