//#region react
import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
//#endregion

//#region libraries
import { Button } from "antd";
//#endregion

//#region actions
import {
    getAgents,
    setAgentsSorting,
    setAgentsFilters,
    forceLogout,
    suspendAgents,
    unsuspendAgents,
    getAgentTotals,
    setAgentBalanceOrCredit
} from "store/actions/dashboard/agentSystem/agents/agents.action";
import { switchAccount } from "store/actions/auth/auth.action";
//#endregion

//#region hooks
import useFirstRender from "hooks/useFirstRender";
import useIncludedColumns from 'hooks/useIncludedColumns';
import useFormat from 'hooks/useFormat';
import { TRANSFER_ENTITY_TYPE, TRANSFER_SUCCESS_EVENT_NAME, useTransfer } from 'components/common/transfer';
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import Confirmation from "components/common/confirmation";
import NavBar from 'components/common/navBar';
import Dropdown from 'components/common/dropdown';
import TotalsComponent from "components/common/totals";
import AgentCreateComponent from "./agent-create.component";
import AgentDetailsComponent from "./details";
import Filters from "./filters.component";
//#endregion

//#region utils
import { isMobile, isFranchisingMode } from 'utils/common';
import {
    getDisabledExpandRowsIds,
    canTransferToAgent,
    canPayCommissionToAgent,
    getNavBarInitialItems,
} from 'utils/agents';
import sessionStorageUtils from "utils/sessionStorage";
import { getUser } from 'utils/auth';
import { hasPermission } from 'utils/permissions';
import { hasAgentEditPageAccess } from "utils/pageAccess";
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import {
    WALLET_TRANSACTION_TYPE
} from 'constants/wallet.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { USER_STATE, USER_ROLE, USER_TYPE } from "constants/user.constants";
import {AGENTS_LITE_DEFAULT_COLUMNS} from "constants/agent.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import Paths from 'constants/path.constants';
import ApiUrls from 'constants/api.constants';
import { ROW_CHOOSER_TYPES } from "components/common/table/constants";
import { REPORT_TOTAL_TYPE } from "constants/reports.constants";
import { getExpandTableColumns, getTableColumns } from "./columns";
//#endregion

//#region types
import agentType from "types/agent/agent.type";
import sortingType from "types/common/sorting.type";
import userInfoType from 'types/profile/userInfo.type';
import useProjectType from "hooks/useProjectType";
import {AGENT_LITE, AGENTS} from "constants/pageName.constants";
//#endregion

const EXPAND_TABLE_ROW_UNIQUE_KEY = "currencyCode";

const CONFIRMATION_POPUP_DEFAULT_DATA = {
    isVisible: false,
    title: "Confirmation",
    message: "",
    onOk: () => { },
}

/** Agents Page Component */
const AgentsComponent = ({
    getAgents,
    agents,
    isLoading,
    total,
    setAgentsSorting,
    setAgentsFilters,
    sorting,
    filters,
    globalCompanyId,
    globalProjectId,
    userInfo,
    forceLogout,
    switchAccount,
    suspendAgents,
    unsuspendAgents,
    getAgentTotals,
    totals,
    isTotalsLoading,
    setAgentBalanceOrCredit
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { search } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const { formatAmount } = useFormat();

    const { show: showTransfer} = useTransfer();

    const { hasRetail, hasLiteMode, hasLatamMode } = useProjectType()

    const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(false);
    const [confirmationPopupData, setConfirmationPopupData] = useState({ ...CONFIRMATION_POPUP_DEFAULT_DATA });

    const [checkedRows, setCheckedRows] = useState([]);
    const [showCheckBoxes, setShowCheckBoxes] = useState(!isMobile());

    const _dropdownRef = useRef(null);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({
        pageName: hasLiteMode ? AGENT_LITE : AGENTS,
        defaultIncludedColumns: hasLiteMode ? AGENTS_LITE_DEFAULT_COLUMNS : undefined
    });

    const [navBarInstance] = NavBar.useNavBar({
        defaultPage: t(`backoffice.menu.${AGENTS}`),
        initialNavItems: getNavBarInitialItems(searchParams.get("agentId")),
    });
    const { addToNavBar, resetNavBar, navigateToTarget, navBarActiveItem, navBarItems } = navBarInstance;

    const showLayoutActions = !navBarActiveItem;

    useFirstRender({
        dependencies: [globalProjectId],
        afterFirstRenderCB: () => resetNavBar(),
    })

    const isMobileDevice = isMobile();
    const franchisingMode = isFranchisingMode();

    const disabledExpandRowsIds = getDisabledExpandRowsIds(agents);

    const { role, userState, userName } = getUser();

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.AGENT,
        action: PERMISSION_ACTION.EXPORT,
    });

    const hasAgentsForceLogoutPermission = hasPermission({
        resource: PERMISSION_RESOURCE.AGENT_FORCE_LOGOUT,
        action: PERMISSION_ACTION.MODIFY
    })

    const hasCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.AGENT,
        action: PERMISSION_ACTION.CREATE
    })

    const hasAgentWalletsModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.AGENT_WALLETS,
        action: PERMISSION_ACTION.MODIFY
    })

    const hasAgentModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.AGENT,
        action: PERMISSION_ACTION.MODIFY
    })

    const hasTotalsPermission = hasPermission({
        resource: PERMISSION_RESOURCE.AGENT_WALLETS_TOTALS,
        action: PERMISSION_ACTION.VIEW
    })

    const canNavigateToAgentEditPage = hasAgentEditPageAccess();

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = (value, item) => {
        setAgentsFilters({
            ...filters,
            nameOrId: value ? item ? item.id : value : ""
        })
    }

    const showForceLogoutPopup = () => {
        setConfirmationPopupData({
            isVisible: true,
            title: t('backoffice.agents.forceLogout'),
            message: t('backoffice.agents.forceLogoutConfirmationMessage'),
            onOk: forceLogout,
        });
    }

    const showSuspendUnsuspendPopup = (type) => {
        setConfirmationPopupData({
            isVisible: true,
            title: type === "suspend" ? t('backoffice.agents.suspendAgent') : t('backoffice.agents.unsuspendAgent'),
            message: type === "suspend" ? t('backoffice.agents.suspendConfirmationMessage') : t('backoffice.agents.unsuspendConfirmationMessage'),
            onOk: () => {
                if (type === "suspend") {
                    suspendAgents(
                        checkedRows,
                        () => {
                            setCheckedRows([]);
                            getAgents()
                        }
                    )
                    return;
                }

                unsuspendAgents(
                    checkedRows,
                    () => {
                        setCheckedRows([]);
                        getAgents()
                    }
                )
            }
        });
    }

    const hideConfirmationPopup = () => {
        setConfirmationPopupData({
            ...CONFIRMATION_POPUP_DEFAULT_DATA
        });
    }

    const showCreatePopup = () => {
        setIsCreatePopupVisible(true);
    }

    const hideCreatePopup = () => {
        setIsCreatePopupVisible(false);
    }

    const handleRightArrowIconClick = (record) => {
        const path = isFranchisingMode() ? Paths.BETSHOP_OWNERS_EDIT : Paths.AGENTS_EDIT;

        navigate(
            `${path}/${record.id}` +
            `?cid=${globalCompanyId}` +
            `&pid=${globalProjectId}` +
            `&name=${record.userName}` +
            `&role=${record.role}`
        )
    }

    const handleUserNameClick = useCallback((record) => {
        addToNavBar({
            navBarTitle: record.userName,
            navBarKey: record.id,
            ...record,
        });
    }, [])

    const handleTableCheckboxChange = value => {
        setCheckedRows(value)
    }

    const showTransferPopup = (record, additionalData={}) => {
        const transferData = {
            entityType: TRANSFER_ENTITY_TYPE.AGENT,
            userId: record.id,
            ...additionalData
        }

        showTransfer(transferData);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        expandTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            expandColumns: getExpandTableColumns,
            includedColumns: includedColumns,
            constructForExport: true,
            constructForInclude: true,
            additionalProps: {
                userInfo,
                hasRetail,
                hasLiteMode,
                franchisingMode,
                formatAmount,
                onUserNameClick: handleUserNameClick,
                canTransferToAgent,
                showTransferPopup
            }
        })
    }, [includedColumns, franchisingMode, hasRetail, handleUserNameClick, formatAmount])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (userState === USER_STATE.ACTIVE) {
        if (role !== USER_ROLE.ADMIN || hasAgentWalletsModifyPermission) {


            if (!hasLiteMode) {
                tableRowActions.push(
                    {
                        title: t("backoffice.agents.transfer"),
                        icon: "icon-transfer",
                        onClick: showTransferPopup,
                        disabled: record => !canTransferToAgent({ agentData: record, userInfo }),
                    },
                )

                tableRowActions.push({
                    title: t("backoffice.wallet.payCommission"),
                    icon: "icon-payCommission",
                    onClick: record => {
                        showTransferPopup(record, {
                            transactionType: WALLET_TRANSACTION_TYPE.AGENT_PAY_DEBT
                        })
                    },
                    disabled: record => !canPayCommissionToAgent({ agentData: record, userInfo }),
                });
            }
        }
    }

    if (role === USER_ROLE.ADMIN && hasAgentModifyPermission && !hasLatamMode) {
        tableRowActions.push({
            title: isFranchisingMode() ? t("backoffice.common.loginAsBetshopOwner") : t("backoffice.common.loginAsAgent"),
            icon: "icon-loginAsAgent",
            onClick: record => switchAccount(record.id),
            disabled: record => record.state !== USER_STATE.IN_PROGRESS && record.state !== USER_STATE.ACTIVE && record.state !== USER_STATE.BLOCKED,
        })
    }

    if (canNavigateToAgentEditPage) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "icon-right",
            onClick: handleRightArrowIconClick,
            disabled: record => record.state === USER_STATE.DELETED || !hasAgentEditPageAccess(record.role)
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    let headerPartsData = {
        search: {
            onSearch: handleSearchChange,
            loadFn: () => {
                getAgents();
            },
            placeholder: t("backoffice.agents.usernameOrId"),
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AGENT,
                dependencies: [globalProjectId],
                actionParams: {
                    excludeAgent: role === USER_ROLE.ADMIN
                }
            }
        },
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        buttons: [
            {
                icon: (isMobileDevice ? "icon-logout" : null),
                type: "secondary",
                className: "rt--button-secondary",
                onClick: showForceLogoutPopup,
                text: t("backoffice.agents.forceLogout"),
                enabled: hasAgentsForceLogoutPermission,
            },
            {
                icon: "icon-plus",
                type: "primary",
                onClick: showCreatePopup,
                enabled: hasCreatePermission,
                text: (
                    franchisingMode
                        ? t('backoffice.agents.createBetshopOwner')
                        : t('backoffice.agents.createAgent')
                ),
            },
            ...hasAgentModifyPermission ? [{
                type: "ghost",
                className: "rt--button-ghost",
                onClick: () => {
                    setCheckedRows([]);
                    setShowCheckBoxes(!showCheckBoxes)
                },
                text: showCheckBoxes ? t("backoffice.common.deselect") : t("backoffice.common.select"),
                enabled: isMobile()
            }] : []
        ],
        breadcrumbs: {
            items: [
                {
                    title: (
                        franchisingMode
                            ? t('backoffice.menu.betshopOwners')
                            : t('backoffice.menu.agents')
                    )
                }
            ],
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            url: ApiUrls.EXPORT_AGENTS,
            columns: columnsForExport,
            filters: filters,
            tableName: (
                franchisingMode
                    ? t('backoffice.menu.betshopOwners')
                    : t('backoffice.menu.agents')
            ),
        }
    }

    const suspendUnsuspendButtonsContent = hasAgentModifyPermission ? (
        <div className={"rt--flex rt--align-center" + (isMobile() ? " rt--justify-between" : "")}>
            <span
                className={"rt--text-secondary " + (isMobile() ? " rt--font-normal rt--font-bold" : " rt--pl-16 rt--pr-16 rt--font-big")}
            >
                {
                    `${checkedRows.length} ${t("backoffice.common.selected")}`
                }
            </span>
            {
                isMobile()
                    ? (
                        <Dropdown
                            ref={_dropdownRef}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            menu={{
                                items: ["suspend", "unsuspend"].map(str => (
                                    {
                                        key: str,
                                        label: (
                                            <span className='rt--title rt--font-normal rt--font-regular'>
                                                {t(`backoffice.agents.${str}`)}
                                            </span>
                                        )
                                    }
                                ))
                            }}
                            button={{
                                className: "rt--button-dropdown",
                                onClick: () => _dropdownRef.current && _dropdownRef.current.open(),
                            }}
                        >
                            {
                                t("backoffice.common.actions")
                            }
                        </Dropdown>
                    ) : (
                        <>
                            <Button
                                type="secondary"
                                className={"rt--button rt--mr-16" + (isMobile() ? "" : " rt--button-secondary rt--button-main-without-icon")}
                                onClick={() => showSuspendUnsuspendPopup("suspend")}
                            >
                                {t("backoffice.agents.suspend")}
                            </Button>
                            <Button
                                type="secondary"
                                className={"rt--button" + (isMobile() ? "" : " rt--button-secondary rt--button-main-without-icon")}
                                onClick={() => showSuspendUnsuspendPopup("unsuspend")}
                            >
                                {t("backoffice.agents.unsuspend")}
                            </Button>
                        </>

                    )
            }
        </div>
    ) : undefined

    if (!isMobile()) {
        if (checkedRows.length > 0) {
            headerPartsData.leftContent = suspendUnsuspendButtonsContent
        }
    }

    //#endregion

    //#region --------------------------------------- EFFECTS -----------------------------------------//

    // Keep items in session storage
    useEffect(() => {
        sessionStorageUtils.set(AGENTS, navBarItems);
    }, [navBarItems]);

    // Update search params on navigation change
    useEffect(() => {
        const agentIdFromSearchParams = searchParams.get("agentId");

        if (!navBarActiveItem) {
            if (agentIdFromSearchParams === null) {
                return;
            }

            setSearchParams("");
            return;
        }

        if (agentIdFromSearchParams === navBarActiveItem.navBarKey) {
            return;
        }

        searchParams.set("agentId", navBarActiveItem.navBarKey);
        setSearchParams(searchParams);
    }, [navBarActiveItem]);

    // Navigate to specific element in case of search params change
    useEffect(() => {
        const agentIdFromSearchParams = searchParams.get("agentId");

        if (!agentIdFromSearchParams) {
            resetNavBar();
            return;
        }

        navigateToTarget(agentIdFromSearchParams);
    }, [search]);

    //#endregion

    /** Reset checked rows on filters, pagination, globalProjectId change */
    const resetCheckboxesFields = [filters, globalProjectId].concat(!isMobile() ? [sorting.page] : [])
    useEffect(() => {
        setCheckedRows([]);
    }, resetCheckboxesFields)

    useEffect(() => {
        const handler = event => {
            const data = event?.detail;
            if(data && data.wallets?.length > 0){
                setAgentBalanceOrCredit(data.wallets)
            }
        }

        document.addEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);
        }
    }, [])

    return (
        <MainDashboardLayout
            header={headerPartsData}
            headerClassName={!showLayoutActions ? "rt--hidden" : ""}
            toolbar={Boolean(navBarActiveItem) ? <NavBar navBarInstance={navBarInstance} /> : undefined}
            extraFooter={checkedRows.length > 0 ? suspendUnsuspendButtonsContent : null}
            isContentScrollable={isMobile() && hasTotalsPermission}
            extraContent={(hasTotalsPermission && !Boolean(navBarActiveItem)) ? ({
                content: (
                    <TotalsComponent
                        getTotals={() => getAgentTotals()}
                        dependencies={[globalProjectId, filters]}
                        totals={totals}
                        isLoading={isTotalsLoading}
                        currencyCode={filters.currencies}
                        totalType={REPORT_TOTAL_TYPE.AGENTS}
                    />
                )
            }) : undefined}
        >
            {
                Boolean(navBarActiveItem) ? (
                    <AgentDetailsComponent
                        addToNavBar={addToNavBar}
                        currentAgent={navBarActiveItem}
                    />
                ) : (
                    <Table
                        loading={isLoading}
                        columns={mainTableColumns}
                        data={agents}
                        loadFn={getAgents}
                        sorting={sorting}
                        setSortingFn={setAgentsSorting}
                        total={total}
                        updateProps={[globalProjectId]}
                        expandAllAvailable={true}
                        shouldShowExpandedColumn={true}
                        actions={tableRowActions}
                        expandable={!hasLiteMode ? {
                            title: t("backoffice.agents.wallets"),
                            disabled: disabledExpandRowsIds,
                            details: (rowData) => ({
                                columns: expandTableColumns,
                                data: rowData?.wallets,
                                uniqueKey: EXPAND_TABLE_ROW_UNIQUE_KEY,
                            }),
                        } : null}
                        chooser={hasAgentModifyPermission && showCheckBoxes ? {
                            type: ROW_CHOOSER_TYPES.CHECKBOX,
                            value: checkedRows,
                            valueIndex: "id",
                            onChange: handleTableCheckboxChange,
                            disabled: (record) => record.state === USER_STATE.DELETED,
                            getCheckboxProps: (record) => {
                                if (record.state === USER_STATE.DELETED || (role === USER_ROLE.AGENT && userName === record.userName)) {
                                    return {
                                        disabled: true,
                                    }
                                }
                            }
                        } : undefined}
                    />
                )
            }

            <Confirmation
                title={confirmationPopupData.title}
                message={confirmationPopupData.message}
                onOk={confirmationPopupData.onOk}
                onCancel={hideConfirmationPopup}
                isVisible={confirmationPopupData.isVisible}
            />

            {isCreatePopupVisible && <AgentCreateComponent onClose={hideCreatePopup} />}
        </MainDashboardLayout>
    );
};

/** AgentsComponent propTypes
 * PropTypes
*/
AgentsComponent.propTypes = {
    /** Redux action to get agents */
    getAgents: PropTypes.func,
    /** Redux state property, represents the array of agents  */
    agents: PropTypes.arrayOf(agentType),
    /** Redux state property, represents the array of totals  */
    totals: PropTypes.objectOf(PropTypes.shape({
        currency: PropTypes.objectOf(PropTypes.shape({
            balance: PropTypes.number,
            credit: PropTypes.number,
            creditLine: PropTypes.number,
        }))
    })),
    /** Redux state property, is true when loading agents */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when loading totals */
    isTotalsLoading: PropTypes.bool,
    /** Redux state property, agents total count */
    total: PropTypes.number,
    /** Redux action to set agents sorting details */
    setAgentsSorting: PropTypes.func,
    /** Redux action to set agents sorting details */
    setAgentsFilters: PropTypes.func,
     /** Redux action to set agents balance or credit */
    setAgentBalanceOrCredit: PropTypes.func,
    /** Redux state property, agents sorting details */
    sorting: sortingType,
    /** Redux state property, agents filters */
    filters: PropTypes.object,
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to force logout */
    forceLogout: PropTypes.func,
    /** Redux action to suspend agents */
    suspendAgents: PropTypes.func,
    /** Redux action to unsuspend agents */
    unsuspendAgents: PropTypes.func,
    /** Redux state property, the user info */
    userInfo: userInfoType,
    /** Redux action to switch account */
    switchAccount: PropTypes.func,
    /** Redux action to get agent totals */
    getAgentTotals: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
    getAgents: nextPage => {
        dispatch(getAgents(nextPage));
    },
    setAgentsSorting: sorting => {
        dispatch(setAgentsSorting(sorting));
    },
    setAgentsFilters: filters => {
        dispatch(setAgentsFilters(filters));
    },
    forceLogout: () => {
        dispatch(forceLogout());
    },
    switchAccount: id => {
        dispatch(switchAccount(id));
    },
    suspendAgents: (agentIds, onSuccess) => {
        dispatch(suspendAgents(agentIds, onSuccess));
    },
    unsuspendAgents: (agentIds, onSuccess) => {
        dispatch(unsuspendAgents(agentIds, onSuccess));
    },
    getAgentTotals: () => {
        dispatch(getAgentTotals());
    },
    setAgentBalanceOrCredit: wallets => {
        dispatch(setAgentBalanceOrCredit(wallets))
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.agents.isLoading,
        isTotalsLoading: state.agents.isTotalsLoading,
        agents: state.agents.agents,
        total: state.agents.total,
        sorting: state.agents.sorting,
        filters: state.agents.filters,
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId,
        userInfo: state.profile.userInfo,
        totals: state.agents.totals,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgentsComponent);
