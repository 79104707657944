import i18n from "translations/config"

import {isFranchisingMode, isMobile, toUpperCaseFirstLetter} from "./common";
import { TIME_PERIOD, ENTITY_TYPE, DATA_TYPES, STATES } from "pages/dashboard/constants";

//#region --------------------------------- OPERATIONS LINE CHART ---------------------------------//

/** Function to fill array wirh zeros, to extends it's lenth
     * @function
     * @param {Array} arr - arr
     * @param {number} length - new array length
     * @returns {Array} - new array
 */
const fillWithZeros = (arr, length) => new Array(length).fill(0).map((el, i) => arr[i] === undefined ? el : arr[i])

/** Function to get operational line chart data
     * @function
     * @param {object} chart - chart loaded data
     * @param {number} timePeriod - time period
     * @param {string} valuePropName - name of the property from which the data is to be constructed
     * @returns {object} - chart data
 */
export const getOperationsLineChartData = (chart, timePeriod, valuePropName = "count", dateService) => {
    const currentMonthDaysCount = dateService.getCurrentMonthDaysCount();

    let categories = [];
    let values = [];
    let dates = [];

    let counts = chart.map(element => element[valuePropName] || 0);
    let times = chart.map(element => element.time);

    switch (timePeriod) {
        case TIME_PERIOD.TODAY:
            categories = dateService.getAllFormatedHours();
            values = fillWithZeros(counts, 24);
            dates = dateService.getAllFormatedHours();
            break;
        case TIME_PERIOD.YESTERDAY:
            categories = chart.map(element => dateService.format(element.time, true, false, false));
            values = counts;
            dates = times;
            break;
        case TIME_PERIOD.THIS_WEEK:
            categories = dateService.getWeekDays().map(el => i18n.t(`backoffice.weekdays.${el}`));
            values = fillWithZeros(counts, 7);
            dates = dateService.getCurrentWeekDays()
            break;
        case TIME_PERIOD.LAST_WEEK:
            categories = dateService.getWeekDays().map(el => i18n.t(`backoffice.weekdays.${el}`));
            values = counts;
            dates = times;
            break;
        case TIME_PERIOD.THIS_MONTH:
            categories = fillWithZeros([], Math.ceil(currentMonthDaysCount / 7)).map((_, i) => i18n.t(`backoffice.dashboard.week`) + " " + (i + 1));
            counts = fillWithZeros(counts, currentMonthDaysCount);
            values = fillWithZeros([], Math.ceil(currentMonthDaysCount / 7))
                .map((_, i) => counts.slice(i * 7, (i + 1) * 7).reduce((acc, a) => acc + a, 0));
            dates = dateService.getCurrentMonthDays();
            break;
        case TIME_PERIOD.LAST_MONTH:
            categories = fillWithZeros([], Math.ceil(chart.length / 7)).map((_, i) => i18n.t(`backoffice.dashboard.week`) + " " + (i + 1));
            values = fillWithZeros([], Math.ceil(chart.length / 7))
                .map((_, i) => counts.slice(i * 7, (i + 1) * 7).reduce((acc, a) => acc + a, 0));
            dates = times;
            break;
        case TIME_PERIOD.LAST_QUARTER:
            categories = chart.map(element => dateService.toString(element.time, "MMM"));
            values = counts;
            dates = times;
            break;
        case TIME_PERIOD.THIS_YEAR:
            categories = dateService.getMonths().map(month => toUpperCaseFirstLetter(month));
            values = fillWithZeros(counts, 12);
            dates = times;
            break;
        default:
            return chart.map(element => element.time)
    }

    return {
        categories,
        values,
        dates,
        counts
    }
}

const tooltipInnerHTML = ({
    title,
    content,
    additional = "",
}) => {
    return `
        <div class="rt--chart-operational-tooltip">
            <div class="rt--flex rt--flex-col">
                <div class="rt--flex rt--flex-col rt--mb-4">
                    <span class='rt--chart-operational-tooltip-title rt--font-small rt--font-bold'>
                        ${title}
                    </span>
                    <span class='rt--chart-operational-tooltip-subTitle rt--font-small rt--font-extra-bold rt--mt-6'>
                        ${content}
                    </span>
                </div>
            </div>
            ${additional}
        </div>
    `
}

const additionalContentHTML = ({ dateTime, value }) => {
    return `
        <div class="rt--flex rt--align-center rt--justify-between rt--mt-8">
            <span class='rt--chart-operational-tooltip-title rt--chart-operational-tooltip-title-point rt--font-small rt--font-bold rt--mr-12 rt--pl-10'>
                ${dateTime}
            </span>
            <span class='rt--chart-operational-tooltip-subTitle rt--font-small rt--font-extra-bold'>
                ${value}
            </span>
        </div>
    `
}

const contentHTML = ({ title, value, subTitle1, subTitle2, subValue1, subValue2 }) => {
    return `
        <div class="rt--flex rt--flex-col">
            ${Boolean(title) || Boolean(value)
            ? (
                `<div class="rt--flex rt--flex-col rt--mb-8">
                    <span class='rt--chart-operational-tooltip-title .rt--font-normal rt--font-bold'>
                        ${title ? title : ""}
                    </span>
                    <span class='rt--chart-operational-tooltip-subTitle .rt--font-normal rt--font-extra-bold rt--mt-6'>
                        ${value ? value : ""}
                    </span>
                </div>`
            ) : (
                ""
            )}
            <div class="rt--flex">
            ${Boolean(subTitle1)
            ? (
                `<div class="rt--flex rt--flex-col rt--mr-16">
                    <span class='rt--chart-operational-tooltip-title rt--font-small rt--font-bold'>
                        ${subTitle1}
                    </span>
                    <span class='rt--chart-operational-tooltip-subTitle rt--font-small rt--font-extra-bold rt--mt-6'>
                        ${subValue1}
                    </span>
                </div>`
            ) : (
                ""
            )}

            ${Boolean(subTitle2)
            ? (
                `<div class="rt--flex rt--flex-col">
                        <span class='rt--chart-operational-tooltip-title rt--font-small rt--font-bold'>
                            ${subTitle2}
                        </span>
                        <span class='rt--chart-operational-tooltip-subTitle rt--font-small rt--font-extra-bold rt--mt-6'>
                            ${subValue2}
                        </span>
                    </div>`
            ) : (
                ""
            )}
            </div>
        </div>
    `
}

/** Function to get operational line chart tooltip
     * @function
     * @param {object} element - current hovered element
     * @param {object} chartData - chart data
     * @param {number} timePeriod - time period
     * @param {number} entityType - entity type
     * @returns {string} - tooltip html
 */
export const getOperationsLineChartTooltip = ({ element, chartData, timePeriod, entityType, dateService }) => {
    console.log({chartData});
    let x = element.x;
    let y = element.y;
    const index = element.point.index;
    let list = "";
    const dates = chartData.dates;
    const counts = chartData.counts;

    let userType = ""

    switch (entityType) {
        case ENTITY_TYPE.AGENT:
            userType = i18n.t("backoffice.common.agent")
            break;
        case ENTITY_TYPE.PLAYER:
            userType = i18n.t("backoffice.common.player")
            break;
        case ENTITY_TYPE.BETSHOP:
            userType = i18n.t("backoffice.common.betshop")
            break;
        default:
            break;
    }

    y = y + " " + userType;

    let startIndex, endIndex;
    switch (timePeriod) {
        case TIME_PERIOD.TODAY:
            x = dateService.format(dateService.getNow(), false, true, false) + " " + x;
            break;
        case TIME_PERIOD.YESTERDAY:
            x = dateService.format(dateService.yesterday(), false, true, false) + " " + x;
            break;
        case TIME_PERIOD.THIS_WEEK:
        case TIME_PERIOD.LAST_WEEK:
            x = dateService.format(dates[index], false, true, false) + " " + x;
            break;
        case TIME_PERIOD.THIS_MONTH:
        case TIME_PERIOD.LAST_MONTH:
            startIndex = index * 7;
            endIndex = Math.min((index + 1) * 7, dates.length);
            for (let i = startIndex; i < endIndex; i++) {
                list += additionalContentHTML({
                    dateTime: dateService.toString(dates[i], "MMM DD"),
                    value: `${counts[i]} ${userType}`
                })
            }
            break;
        case TIME_PERIOD.LAST_QUARTER:
        case TIME_PERIOD.THIS_YEAR:
            x = dateService.toString(dates[index], "MMMM YYYY");
            break;
        default:
            break;
    }

    return tooltipInnerHTML({ title: x, content: y, additional: list });
}

const getAxisesData = (element) => {
    return {
        xAxisValue: element.x,
        xAxisIndex: element.point.index,
        yAxisValue: element.y,
        lineName: element.series.name,
    }
}

const tooltipValueFormatter = ({
    value,
    currencyCode,
    hasLiteMode,
    formatAmount,
    formatCurrencyWithSymbol
}) => {
    value = formatAmount(value, currencyCode);

    if (hasLiteMode) {
        return formatCurrencyWithSymbol(value, currencyCode);
    }

    return `${value} ${currencyCode}`;
}

export const getFinancialTooltipHTML = (element, additionalData) => {
    const { xAxisValue, yAxisValue, xAxisIndex, lineName } = getAxisesData(element);
    const {
        hasLiteMode,
        chartData,
        currencyCode,
        dataType,
        dates,
        counts,
        timePeriod,
        formatAmount,
        formatCurrencyWithSymbol,
        dateService
    } = additionalData;
    const boundedFormatter = (value) => tooltipValueFormatter({
        value,
        hasLiteMode,
        currencyCode,
        formatAmount,
        formatCurrencyWithSymbol
    });

    let tooltipTitle = xAxisValue; // default
    let content = boundedFormatter(yAxisValue); // default
    let additionalContent = "" // default

    //#region ------------------------------------ TOOLTIP TITLE -------------------------------------- //

    if (TIME_PERIOD.TODAY === timePeriod) {
        tooltipTitle = `${dateService.format(dateService.getNow(), false, true, false)} ${xAxisValue}`;
    }

    if (TIME_PERIOD.YESTERDAY === timePeriod) {
        tooltipTitle = `${dateService.format(dateService.yesterday(), false, true, false)} ${xAxisValue}`;
    }

    if ([TIME_PERIOD.LAST_WEEK, TIME_PERIOD.THIS_WEEK].includes(timePeriod)) {
        tooltipTitle = `${dateService.format(dates[xAxisIndex], false, true, false)} ${xAxisValue}`;
    }

    if ([TIME_PERIOD.LAST_QUARTER, TIME_PERIOD.THIS_YEAR].includes(timePeriod)) {
        tooltipTitle = dateService.toString(dates[xAxisIndex], "MMMM YYYY");
    }


    //#endregion

    //#region ----------------------------------- TOOLTIP CONTENT ------------------------------------- //

    if (dataType === DATA_TYPES.REGISTERED_PLAYERS) {
        content = contentHTML({
            value: yAxisValue + " " + i18n.t("backoffice.dashboard.player"),
        })
    }

    if ([DATA_TYPES.GGR, DATA_TYPES.NGR, DATA_TYPES.TURNOVER].includes(dataType)) {
        let data = {};

        if (lineName === i18n.t("backoffice.dashboard.betShop")) {
            data = {
                title: i18n.t("backoffice.dashboard.betShop"),
                value: `${formatAmount(yAxisValue, currencyCode)} ${currencyCode}`,
            }
        } else {
            if ([TIME_PERIOD.LAST_MONTH, TIME_PERIOD.THIS_MONTH].includes(timePeriod)) {
                let sport = 0;
                let casino = 0;

                const startIndex = xAxisIndex * 7;
                const endIndex = Math.min((xAxisIndex + 1) * 7, dates.length);

                for (let i = startIndex; i < endIndex; i++) {
                    sport += chartData[i]?.sportAmount ? chartData[i]?.sportAmount : 0;
                    casino += chartData[i]?.casinoAmount ? chartData[i]?.casinoAmount : 0;
                }

                if (!isFranchisingMode()) {
                    data = {
                        subTitle1: i18n.t("backoffice.dashboard.sport"),
                        subValue1: boundedFormatter(sport),
                        subTitle2: i18n.t("backoffice.dashboard.casino"),
                        subValue2: boundedFormatter(casino)
                    }
                }
            } else {
                if (!isFranchisingMode()) {
                    data = {
                        subTitle1: i18n.t("backoffice.dashboard.sport"),
                        subValue1: boundedFormatter(chartData[xAxisIndex]?.sportAmount ? chartData[xAxisIndex]?.sportAmount : 0),
                        subTitle2: i18n.t("backoffice.dashboard.casino"),
                        subValue2: boundedFormatter(chartData[xAxisIndex]?.casinoAmount ? chartData[xAxisIndex]?.casinoAmount : 0),
                    }
                }
            }

            if (hasLiteMode) {
                data.title = i18n.t("backoffice.dashboard.earning");
                data.value = boundedFormatter(yAxisValue);
            } else {
                data.title = i18n.t("backoffice.dashboard.player");
                data.value = boundedFormatter(yAxisValue);
            }
        }

        content = contentHTML({
            ...data
        })
    }

    if (hasLiteMode && DATA_TYPES.COMMISSIONS_LITE === dataType) {
        let data = {
            title: i18n.t("backoffice.dashboard.earning"),
            value: boundedFormatter(yAxisValue, currencyCode),
        }

        if (!isFranchisingMode()) {
            data = {
                ...data,
                subTitle1: i18n.t("backoffice.dashboard.sport"),
                subValue1: boundedFormatter(chartData[xAxisIndex]?.sportAmount),
                subTitle2: i18n.t("backoffice.dashboard.casino"),
                subValue2: boundedFormatter(chartData[xAxisIndex]?.casinoAmount)
            }
        }

        content = contentHTML({
            ...data
        })
    }

    if (DATA_TYPES.NET_DEPOSIT === dataType) {
        if ([TIME_PERIOD.LAST_MONTH, TIME_PERIOD.THIS_MONTH].includes(timePeriod)) {
            let deposit = 0;
            let withdraw = 0;

            const startIndex = xAxisIndex * 7;
            const endIndex = Math.min((xAxisIndex + 1) * 7, dates.length);

            for (let i = startIndex; i < endIndex; i++) {
                deposit += chartData[i]?.depositAmount ? chartData[i]?.depositAmount : 0;
                withdraw += chartData[i]?.withdrawAmount ? chartData[i]?.withdrawAmount : 0;
            }

            content = contentHTML({
                title: i18n.t("backoffice.dashboard.netDeposit"),
                value: boundedFormatter(yAxisValue),
                ...(
                    !isFranchisingMode()
                        ? {
                            subTitle1: i18n.t("backoffice.dashboard.deposit"),
                            subValue1: boundedFormatter(deposit),
                            subTitle2: i18n.t("backoffice.dashboard.withdraw"),
                            subValue2: boundedFormatter(withdraw)
                        }
                        : {}
                )
            })
        } else {
            content = contentHTML({
                title: i18n.t("backoffice.dashboard.netDeposit"),
                value: boundedFormatter(yAxisValue),
                ...(
                    !isFranchisingMode()
                        ? {
                            subTitle1: i18n.t("backoffice.dashboard.deposit"),
                            subValue1: boundedFormatter(chartData[xAxisIndex]?.depositAmount ? chartData[xAxisIndex]?.depositAmount : 0),
                            subTitle2: i18n.t("backoffice.dashboard.withdraw"),
                            subValue2: boundedFormatter(chartData[xAxisIndex]?.withdrawAmount ? chartData[xAxisIndex]?.withdrawAmount : 0)
                        }
                        : {}
                )
            })
        }
    }

    //#endregion

    //#region ------------------------------ TOOLTIP ADDITIONAL CONTENT ------------------------------- //

    if ([TIME_PERIOD.LAST_MONTH, TIME_PERIOD.THIS_MONTH].includes(timePeriod)) {
        const startIndex = xAxisIndex * 7;
        const endIndex = Math.min((xAxisIndex + 1) * 7, dates.length);

        for (let i = startIndex; i < endIndex; i++) {
            additionalContent += (
                additionalContentHTML({
                    dateTime: dateService.toString(dates[i], "MMM DD"),
                    value: boundedFormatter(counts[i])
                })
            )
        }
    }

    //#endregion

    return tooltipInnerHTML({
        title: tooltipTitle,
        content,
        additional: additionalContent,
    })
}

//#endregion

//#region --------------------------------- OPERATIONS PIE CHART ---------------------------------//

/** Function to make operational pie chart series data
     * @function
     * @param {object} chartData - chart data
     * @param {Array} inactiveStates - incative states
     * @returns {array} - series data
 */
const makeOperationsPieChartSeries = (chartData, inactiveStates) => {
    const stateTitles = {
        [STATES.ACTIVE]: {
            title: i18n.t("backoffice.common.active"),
            y: chartData.data[STATES.ACTIVE],
            color: 'rgba(0, 186, 136, 1)',
            className: 'HighchartActive',
            arrayIndex: 0
        },
        [STATES.IN_PROGRESS]: {
            title: i18n.t("backoffice.common.inactive"),
            y: chartData.data[STATES.IN_PROGRESS],
            color: 'rgba(255, 190, 12, 1)',
            className: 'HighchartInactive',
            arrayIndex: 1
        },
        [STATES.BLOCKED]: {
            title: i18n.t("backoffice.common.blocked"),
            y: chartData.data[STATES.BLOCKED],
            color: 'rgba(255, 0, 110, 1)',
            className: 'HighchartBlocked',
            arrayIndex: 2
        },
        [STATES.DELETED]: {
            title: i18n.t("backoffice.common.deleted"),
            y: chartData.data[STATES.DELETED],
            color: 'rgba(208, 208, 218, 1)',
            className: 'HighchartDeleted',
            arrayIndex: 3
        },
        // [STATES.EXPIRED]: {
        //     title: i18n.t("backoffice.common.expired"),
        //     y: chartData.data[STATES.EXPIRED],
        //     color: 'rgba(208, 208, 218, 1)',
        //     className: 'HighchartDeleted',
        //     arrayIndex: 4
        // },
        // [STATES.LOCKED_2_FA]: {
        //     title: i18n.t("backoffice.agents.lockedForFailed2FA"),
        //     y: chartData.data[STATES.LOCKED_2_FA],
        //     color: 'rgba(208, 208, 218, 1)',
        //     className: 'HighchartDeleted',
        //     arrayIndex: 5
        // },
        // [STATES.LOCKED_LOGIN]: {
        //     title: i18n.t("backoffice.agents.lockedForFailedLogin"),
        //     y: chartData.data[STATES.LOCKED_LOGIN],
        //     color: 'rgba(208, 208, 218, 1)',
        //     className: 'HighchartDeleted',
        //     arrayIndex: 6
        // },
        // [STATES.PARTIALLY_BLOCK]: {
        //     title: i18n.t("backoffice.players.partiallyBlocked"),
        //     y: chartData.data[STATES.PARTIALLY_BLOCK],
        //     color: 'rgba(208, 208, 218, 1)',
        //     className: 'HighchartDeleted',
        //     arrayIndex: 7
        // },
        // [STATES.UNKNOWN]: {
        //     title: i18n.t("backoffice.players.unknown"),
        //     y: chartData.data[STATES.UNKNOWN],
        //     color: 'rgba(208, 208, 218, 1)',
        //     className: 'HighchartDeleted',
        //     arrayIndex: 7
        // },
    }

    let data = [];
    const states = [
        STATES.ACTIVE,
        STATES.IN_PROGRESS,
        STATES.BLOCKED,
        STATES.DELETED,
        // STATES.EXPIRED,
        // STATES.LOCKED_2_FA,
        // STATES.LOCKED_LOGIN,
        // STATES.PARTIALLY_BLOCK,
        // STATES.UNKNOWN,
    ];

    states.forEach(state => {
        data.push(
            {
                name: stateTitles[state].title,
                y: stateTitles[state].y,
                color: stateTitles[state].color,
                className: stateTitles[state].className,
            }
        )
    });


    const nonZeroSeries = Object.keys(chartData.data);

    const activeSeries = nonZeroSeries.filter(s => {
        const title = stateTitles[s]?.title;
        return !inactiveStates.includes(title)
    });

    const activeSeriesIndexes = activeSeries.map(s => stateTitles[s]?.arrayIndex);
    const activeSeriesTotal = activeSeries.map(s => chartData.data[s]).reduce((a, acc) => a + acc, 0);
    let dataIndex = 0;

    let emptySpaceSize = activeSeriesTotal * 2 / 100;

    data = Array.from({ length: (2 * data.length) }, (_, i) => {
        if (
            i % 2 === 1
        ) {
            return {
                y: activeSeries.length > 1 && activeSeriesIndexes.includes((i - 1) / 2) ? emptySpaceSize : 0,
                className: 'disabled',
            }
        }
        return data[dataIndex++];
    })

    return {
        data,
        total: activeSeriesTotal
    };
}

/** Function to get operational pie chart tooltip
     * @function
     * @param {object} element - current hovered element
     * @param {number} entityType - entity type
     * @returns {string} - tooltip html
 */
const getOperationsPieChartTooltip = (element, entityType) => {
    let userType = ""

    switch (entityType) {
        case ENTITY_TYPE.AGENT:
            userType = i18n.t("backoffice.common.agent")
            break;
        case ENTITY_TYPE.PLAYER:
            userType = i18n.t("backoffice.common.player")
            break;
        case ENTITY_TYPE.BETSHOP:
            userType = i18n.t("backoffice.common.betshop")
            break;
        default:
            break;
    }
    return `<div class="rt--chart-operational-tooltip">
                <div class="rt--flex rt--flex-col"> 
                    <div class="rt--flex rt--flex-col rt--mb-4">
                        <span class='rt--chart-operational-tooltip-title rt--font-small rt--font-bold'>${element.key}</span> 
                        <span class='rt--chart-operational-tooltip-subTitle rt--font-small rt--font-extra-bold rt--mt-6'>${element.y + ' ' + userType}</span>
                    </div>
                </div>
            </div>`;
}

/** Function to get operational pie chart options
     * @function
     * @param {object} chartData - chart data
     * @param {number} entityType - entity type
     * @param {Array} inactiveStates - incative states
     * @param {function} onItemClick - on state item click
     * @returns {object} - chart data
 */
export const getOperationsPieChartOptions = (chartData, entityType, inactiveStates, onItemClick) => {
    const { data, total } = makeOperationsPieChartSeries(chartData, inactiveStates);

    return {
        chart: {
            type: 'pie',
            height: isMobile() ? '360' : '36.5%',
            width: isMobile() ? 300 : 480,
        },

        accessibility: {
            enabled: false
        },

        title: {
            align: 'center',
            verticalAlign: 'middle',
            x: (isMobile() ? 3 : -109),
            y: (isMobile() ? -25 : 14),
            text: `<span class="rt--chart-operational-pie-circle-title rt--font-extra-bold ${isMobile() ? ' rt--font-bigest' : ' rt--font-big'}">${total}</span>`,
        },

        tooltip: {
            useHTML: true,
            borderWidth: 0,
            backgroundColor: "transparent",
            shadow: false,
            formatter: function () {
                return getOperationsPieChartTooltip(this, entityType)
            }
        },

        credits: {
            enabled: false
        },

        legend: {
            align: 'right',
            verticalAlign: 'middle',
            itemStyle: {
                fontSize: isMobile() ? '12px' : '14px',
                color: "#14142B",
                fontWeight: '400'
            },
            navigation: {
                enabled: false,
            }
        },


        plotOptions: {
            pie: {
                size: isMobile() ? '234px' : '140px',
                startAngle: -25,
                center: isMobile() ? ["122px", "34%"] : ["100px", "50%"],
                showInLegend: true,
                point: {
                    events: {
                        legendItemClick: function (e) {

                            const name = e.target.name;
                            const isVisible = e.target.visible;
                            onItemClick && onItemClick(name, isVisible)

                        }
                    }
                }
            },
            series: {
                borderRadius: 4,
                innerSize: '72%',
                dataLabels: {
                    enabled: false,
                }
            }
        },

        series: [{
            data,
        }],

    }
}

//#endregion

//#region ------------------------------ SELECT FIELD OPTION CONSTRUCTOR ------------------------------//

export const getSelectFieldOptions = ({
    translationSource,
    dataSource,
    propsKeyNames = {},
}) => {
    const sortedTypes = Object.values(dataSource);

    const { key = "value", value = "text" } = propsKeyNames;

    return sortedTypes.map(type => ({
        [key]: type,
        [value]: i18n.t(translationSource[type])
    }))
}

//#endregion
